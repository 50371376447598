import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { worker } from "mocks/browser";
import initTagManager from "utils/tag-manager";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

initTagManager();
// worker.start();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
