import { authPaths } from "components/router/routes";
import { UserFFragment, SetupStage } from "generated/graphql";

export const getNextOnboardingPath = (
  currentPathName: string,
  user: UserFFragment | null
) => {
  // anonymous user have access to sign in, sign up and verify pages
  if (!user) {
    if (
      currentPathName === authPaths.SIGNIN ||
      currentPathName === authPaths.SIGNUP ||
      currentPathName === authPaths.VERIFY_CODE
    ) {
      return null;
    }
    return authPaths.SIGNIN;
  }

  // Users should stick to their setup stage
  if (user.company) {
    if (user.company.setupStage === SetupStage.Script) {
      if (currentPathName === authPaths.CONNECT) {
        return null;
      }
      return authPaths.CONNECT;
    }
    if (user.company.setupStage === SetupStage.Role) {
      if (currentPathName === authPaths.VERIFY_SETUP) {
        return null;
      }
      const cloud = user.company.cloudAccounts?.edges[0]?.node?.cloud;
      return `${authPaths.VERIFY_SETUP}/${cloud}`;
    }
    if (user.company.setupStage === SetupStage.Region) {
      if (currentPathName === authPaths.CHOOSE_REGIONS) {
        return null;
      }
      return authPaths.CHOOSE_REGIONS;
    }
    if (user.company.setupStage === SetupStage.Processing) {
      if (currentPathName === authPaths.AWS_RESULTS_PROCESSING) {
        return null;
      }
      return authPaths.AWS_RESULTS_PROCESSING;
    }
    if (user.company.setupStage === SetupStage.Results) {
      if (currentPathName === authPaths.DIAGNOSTIC_DASHBOARD) {
        return null;
      }
      return authPaths.DIAGNOSTIC_DASHBOARD;
    }
  }
  // TODO: throw an error, all cases should be handled here
  // eslint-disable-next-line no-console
  console.error(`Why we didn't return here?`, { user, currentPathName });
  return null;
};
