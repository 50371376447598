/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Box, Button, Grid } from "@material-ui/core";
import OnboardingBar from "components/navigation/OnboardingBar";
import * as React from "react";
import ConnectAws from "components/connect/ConnectAws";
import ConnectGcp from "components/connect/ConnectGcp";
import useRequireAuth from "hooks/use-require-auth";
import Hero from "components/onboarding/Hero";
import PlatformRadioGroup from "components/platform/PlatformRadioGroup";

interface Props {
  platform?: string;
}

const ConnectPage: React.FC<Props> = () => {
  const { user } = useRequireAuth();
  const [platform, setPlaform] = React.useState<string>(
    user?.company?.cloudAccounts?.edges[0]?.node?.cloud || "aws"
  );
  const [next, setNext] = React.useState<boolean>(
    !!user?.company?.cloudAccounts?.edges[0]?.node?.cloud
  );
  const platforms = [
    {
      name: "Amazon Web Services",
      value: "aws",
      // eslint-disable-next-line global-require
      img: () => require("assets/platforms/aws.png"),
    },
    {
      name: "Google Cloud Platform",
      value: "gcp",
      // eslint-disable-next-line global-require
      img: () => require("assets/platforms/gcp.png"),
    },
  ];
  const ChoosePlatform: React.FC = () => {
    return (
      <Grid
        container
        sx={{
          bg: "white",
          borderRadius: 10,
          minHeight: [, 515],
          maxWidth: ["100%", 1240],
          mx: "auto",
          pt: [20, 0],
        }}
      >
        <Grid item sm={4} md={6} container justify="center" alignItems="center">
          <Grid item md={8}>
            <Hero title="Please choose a cloud service to diagnose." />
          </Grid>
        </Grid>
        <Grid
          item
          sm={8}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            pl: 3,
            pr: 3,
          }}
        >
          <PlatformRadioGroup
            selected={platform}
            setSelected={setPlaform}
            items={platforms}
          />
          <Grid container justify="flex-end" sx={{ pr: 4, pb: 4 }}>
            <Grid item xs={3}>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => setNext(true)}
                fullWidth
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const Container: React.FC = ({ children }) => {
    return (
      <Box
        sx={{
          backgroundSize: "cover",
          backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png)`,
          height: "100%",
          minHeight: "100vh",
          px: 20,
        }}
      >
        <OnboardingBar
          sx={{
            mt: 88,
          }}
        />
        {children}
      </Box>
    );
  };
  const Connect: React.FC = () => {
    if (next && platform === "gcp") return <ConnectGcp />;
    if (next && platform === "aws") return <ConnectAws />;
    return <ChoosePlatform />;
  };
  return (
    <Container>
      <Connect />
    </Container>
  );
};
ConnectPage.displayName = "ConnectPage";
export default ConnectPage;
