/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import React from "react";

import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

interface Props {
  title: string;
}
const Hero: React.FC<Props> = ({ title, children }) => {
  return (
    <Box sx={{ pl: [30, , 0], pr: [30, 0] }}>
      <Typography
        component="h1"
        variant="h2"
        sx={{
          color: "#EF7B2E",
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
Hero.displayName = "Onboarding Hero";
export default Hero;
