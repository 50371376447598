/** @jsxImportSource theme-ui */
import { Button, Grid, Typography } from "@material-ui/core";
import VerifyAWSSetupForm from "components/verify-setup/VerifyAWSSetupForm";
import useRouter from "hooks/use-router";
import * as React from "react";

const VerifyAWSSetup: React.FC = () => {
  const { history } = useRouter();
  const goBack = () => history.goBack();

  return (
    <Grid
      container
      sx={{
        bg: "white",
        borderRadius: 10,
        minHeight: 515,
        maxWidth: 1240,
        mx: "auto",
      }}
    >
      <Grid item sm={4} md={6} container justify="center" alignItems="stretch">
        <Grid
          item
          container
          alignItems="center"
          sx={{
            flexGrow: 0,
            flexBasis: [, "460px"],
            position: "relative",
          }}
        >
          <Grid item sx={{ pl: [30, , 0] }}>
            <Typography
              component="h1"
              variant="h2"
              sx={{
                color: "#EF7B2E",
              }}
            >
              Verify your AWS Setup
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={8} md={6}>
        <VerifyAWSSetupForm />
      </Grid>
    </Grid>
  );
};
VerifyAWSSetup.displayName = "VerifyAWSSetup";
export default VerifyAWSSetup;
