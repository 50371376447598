/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import Hero from "components/onboarding/Hero";
import { useSetupRoleMutation } from "generated/graphql";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropBox from "components/verify-setup/VerifyGCPDnD";
import OnboardingBar from "components/navigation/OnboardingBar";
import VerifyGCPModal from "components/verify-setup/VerifyGCPModal";
import { useAuth } from "hooks/use-auth";

const VerifyGCP: React.FC = () => {
  const { profileQuery } = useAuth();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [projectName, setProjectName] = React.useState<string>("");
  const [dataSet, setDataSet] = React.useState<string>("");
  const [droppedFiles, setDroppedFiles] = React.useState<File[]>([]);
  const setupRole = useSetupRoleMutation({
    onSuccess: () => {
      profileQuery?.refetch();
    },
  });
  const isLoading = setupRole.isLoading || setupRole.isSuccess;
  const disabled = isLoading || droppedFiles.length <= 0;
  const handleSubmit = async () => {
    const fileContents = await droppedFiles[0].text();
    try {
      await setupRole?.mutateAsync({
        credentials: {
          file: fileContents,
          projectName,
          dataSet,
        },
      });
    } catch (e) {
      setIsModalOpen(true);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png);`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
          px: [30, 0],
        }}
      />
      <Grid container justify="center" sx={{ minHeight: "587px" }}>
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            item
            sm={4}
            md={6}
            lg={6}
            container
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ mt: 4 }}>
              <Hero title="Verify your GCP setup.">
                <Link
                  href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys"
                  target="_blank"
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{ mt: 2, fontWeight: 500 }}
                  >
                    Where do I find my Google Account Service Key?
                  </Typography>
                </Link>
              </Hero>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8} md={6} sx={{ my: 5, px: 4 }}>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
                mb: 2,
                color: "#F26D2F",
                fontWeight: 600,
              }}
            >
              Billing Information
            </Typography>
            <TextField
              size="small"
              fullWidth
              id="project-name"
              label="Cost Project Name (optional)"
              variant="outlined"
              sx={{ mb: 3 }}
              onChange={(val) => setProjectName(val.target.value)}
            />
            <TextField
              size="small"
              fullWidth
              id="dataset-name"
              label="Cost DataSet Name (optional)"
              variant="outlined"
              sx={{ mb: 3 }}
              onChange={(val) => setDataSet(val.target.value)}
            />
            <Link
              href="https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#daily-cost-detail"
              sx={{ mt: 2, mb: 2 }}
              target="_blank"
            >
              <Typography variant="caption" color="primary">
                Where do I find project and dataset names?
              </Typography>
            </Link>
            <Box sx={{ mb: 2 }}>
              <Typography variant="caption" sx={{ color: "#778899" }}>
                If you do not provide us access to your Billing BigQuery export,
                we will not get accurate info on your cloud spend
              </Typography>
            </Box>
            <DndProvider backend={HTML5Backend}>
              <DropBox onDrop={setDroppedFiles} />
            </DndProvider>
          </Grid>
          <Grid
            container
            sx={{ mb: 4, mx: 4 }}
            justify="flex-end"
            alignContent="flex-end"
          >
            <Grid item xs={2}>
              <Button
                disabled={disabled}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <VerifyGCPModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </Box>
  );
};
VerifyGCP.displayName = "VerifyGCP";
export default VerifyGCP;
