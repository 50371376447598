/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useAuth } from "hooks/use-auth";
import useRouter from "hooks/use-router";
import React from "react";
import { RegisterOptions, useForm } from "react-hook-form";

import { authPaths } from "components/router/routes";
import { useVerifyResendMutation } from "generated/graphql";
import ErrorBox from "./ErrorBox";
import fields, { InputField, InputValues } from "./signinFields";

interface SignInFormProps {
  handleForgotPassword: () => void;
}

const SignInForm: React.FC<SignInFormProps> = ({ handleForgotPassword }) => {
  const { signin } = useAuth();
  const verifyResend = useVerifyResendMutation();
  const { push } = useRouter();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState,
  } = useForm<InputValues>({
    mode: `all`,
    defaultValues: fields.reduce(
      (acc, field) => ({ ...acc, [field.props.id]: field.defaultValue }),
      {}
    ),
  });
  const isLoading = signin?.isLoading || signin?.isSuccess;
  const disabled = !formState.isValid || isLoading;
  const currentEmail = watch("email");

  const onSubmitHandler = handleSubmit(async ({ email, password }) => {
    signin?.mutate({ email, password });
  });

  return (
    <div>
      <form noValidate onSubmit={onSubmitHandler}>
        <Grid item direction="row" container alignItems="center">
          {fields.map((field: InputField) => {
            const { id, label, type } = field.props;
            const error = errors[id];
            const registerOptions: RegisterOptions = {
              required: `You must provide a ${label}`,
            };
            return (
              <Grid item xs={12} direction="row" container key={field.props.id}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputRef={register(registerOptions)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={id}
                    label={label}
                    name={id}
                    type={type}
                    sx={{ color: "#90969", mb: 0 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {error && <ErrorBox>{error.message}</ErrorBox>}
                </Grid>
              </Grid>
            );
          })}

          <Grid item container xs={12} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                color="primary"
                fullWidth
              >
                {isLoading ? <CircularProgress size={28} /> : "Sign in"}
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={6} sx={{ textAlign: "center", pt: 2 }}>
              <Button
                variant="text"
                color="primary"
                onClick={handleForgotPassword}
              >
                Forgot Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {signin?.isError && (
        <Grid item xs={12} lg={6}>
          {signin?.error?.graphqlErrors?.map((error, index) => {
            let messageOrElement: string | React.ReactNode = error.message;
            if (messageOrElement === "User is not confirmed") {
              const handleVerifyEmail = async () => {
                await verifyResend.mutateAsync({ email: currentEmail });
                push(
                  `${authPaths.VERIFY_CODE}?email=${encodeURIComponent(
                    currentEmail
                  )}`
                );
              };
              messageOrElement = (
                <>
                  {messageOrElement}. Click
                  <Button
                    onClick={handleVerifyEmail}
                    sx={{
                      p: 0,
                      mx: "-5px",
                    }}
                  >
                    here
                  </Button>
                  to confirm it now
                </>
              );
            }
            return (
              <Alert severity="error" key={`${error.message}-${index}`}>
                {messageOrElement}
              </Alert>
            );
          })}
        </Grid>
      )}
      {verifyResend?.isError && (
        <Grid item xs={12} lg={6}>
          {verifyResend?.error?.graphqlErrors?.map((error, index) => {
            return (
              <Alert severity="error" key={`${error.message}-${index}`}>
                {error.message}
              </Alert>
            );
          })}
        </Grid>
      )}
    </div>
  );
};
SignInForm.displayName = `Signin Form`;
export default SignInForm;
