/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

export type RenderButtonProps = {
  onClick: () => void;
  success: boolean;
};

export type RenderButton = React.FC<RenderButtonProps>;

type VerifyGCPModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
};

const VerifyGCPModal: React.FC<VerifyGCPModalProps> = ({
  isModalOpen,
  closeModal,
}) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      maxWidth="sm"
      sx={{ ".MuiDialog-paperWidthSm": { width: "600px" } }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            mt: -2,
            pb: 2,
            px: 2,
            maxWidth: 600,
          }}
        >
          <IconButton onClick={closeModal} sx={{ ml: "auto" }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ color: "#EF7B2E" }}>
            We were unable to connect your Google Service Account Key
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ overflow: "auto", maxWidth: 600 }}>
        <Box sx={{ px: 2, pb: 3 }}>
          <Typography variant="body1" sx={{ my: 2 }}>
            Please visit Google’s documentation guides&nbsp;
            <Link href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys">
              Creating and managing service account keys&nbsp;
            </Link>
            and try again.
          </Typography>

          <Box sx={{ mt: 4 }} justifyContent="center">
            <Button
              onClick={closeModal}
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            >
              Close
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
VerifyGCPModal.displayName = "ContactModal";
export default VerifyGCPModal;
