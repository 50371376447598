/** @jsxImportSource theme-ui */

import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import React from "react";
import ReactHookFormSelect from "../general/ReactHookFormSelect";
import { useRegionsQuery } from "../../generated/graphql";

interface FormValues {
  businessUnitId: string;
  accountName: string;
  accountNumber: string;
  regionId: string;
}
interface Props {
  // Should be company fragment but it doesn't has required props
  businessUnitOptions: Array<{
    id: string;
    name: string;
  }>;
  closeModal: () => void;
}
const AccountCreationForm: React.FC<Props> = ({
  businessUnitOptions,
  closeModal,
}) => {
  const regionsQuery = useRegionsQuery();
  const availableRegions = (regionsQuery.data?.aws?.regions as string[]) || [];
  const { register, handleSubmit, reset, control } = useForm<FormValues>({
    defaultValues: {
      businessUnitId: "",
      accountName: "",
      accountNumber: "",
      regionId: "",
    },
  });

  // eslint-disable-next-line
  const commonSubmit = async (values: FormValues) => {
    // call mutation
  };
  const onSubmit = handleSubmit(async (values) => {
    await commonSubmit(values);
    closeModal();
  });
  const handleSaveAndCreate = handleSubmit(async (values) => {
    await commonSubmit(values);
    reset();
  });
  return (
    <Box component="form" onSubmit={onSubmit} sx={{ pb: 3, maxWidth: 550 }}>
      <ReactHookFormSelect
        control={control}
        defaultValue=""
        sx={{ mt: 15, mb: 10 }}
        id="account-form-bu"
        name="businessUnitId"
        fullWidth
        variant="outlined"
        label="Choose Business Unit"
        data-testid="account-form-bu"
        required
        inputProps={{ required: true }}
      >
        {businessUnitOptions.map(({ name, id }) => (
          <MenuItem value={id} key={id}>
            {name}
          </MenuItem>
        ))}
      </ReactHookFormSelect>
      <TextField
        id="account-form-account-name"
        name="accountName"
        label="Enter Account Name"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
      />
      <TextField
        id="account-form-account-number"
        name="accountNumber"
        label="Enter Account Number"
        variant="outlined"
        margin="normal"
        fullWidth
        inputProps={{ required: true }}
        inputRef={register({ required: true })}
        type="number"
      />
      <ReactHookFormSelect
        control={control}
        defaultValue=""
        sx={{ mt: 15, mb: 10 }}
        id="account-form-region"
        name="regionId"
        fullWidth
        variant="outlined"
        label="Select a region"
        data-testid="account-form-region"
        required
        inputProps={{ required: true }}
      >
        {availableRegions.map((region) => (
          <MenuItem value={region} key={region}>
            {region}
          </MenuItem>
        ))}
      </ReactHookFormSelect>
      <Divider sx={{ mt: 4, mx: -3 }} />
      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            sx={{ width: 90 }}
          >
            Add
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            type="button"
            onClick={handleSaveAndCreate}
          >
            Save and Create Another
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="text"
            sx={{ width: 90 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
AccountCreationForm.displayName = "AccountCreationForm";
export default AccountCreationForm;
