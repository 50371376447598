import { authPaths } from "components/router/routes";
import { ReactComponent as IconConnectCloud } from "assets/icons/icon-connect-cloud.svg";
import { ReactComponent as IconAwsProcessing } from "assets/icons/icon-aws-processing.svg";
import { ReactComponent as IconCloudVerified } from "assets/icons/icon-cloud-verified.svg";
import { ReactComponent as IconEmailVerify } from "assets/icons/icon-email-verify.svg";
import { ReactComponent as IconSelectRegions } from "assets/icons/icon-select-regions.svg";

export const onboardingRoutes = [
  {
    path: authPaths.VERIFY_CODE,
    icon: IconEmailVerify,
  },

  {
    path: authPaths.CONNECT,
    icon: IconConnectCloud,
  },
  {
    path: authPaths.VERIFY_SETUP,
    icon: IconCloudVerified,
  },
  {
    path: authPaths.CHOOSE_REGIONS,
    icon: IconSelectRegions,
  },
  {
    path: authPaths.AWS_RESULTS_PROCESSING,
    icon: IconAwsProcessing,
  },
];
