/** @jsxImportSource theme-ui */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import DisplayTemplate from "./Template";

interface Props {
  availableRegions: any;
  region: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  setupScript: any;
  templateQuery: any;
}
const SelectTemplate: React.FC<Props> = ({
  availableRegions,
  region,
  setupScript,
  templateQuery,
  onChange,
}) => {
  return (
    <Box sx={{ marginTop: 3 }}>
      <Box sx={{ textAlign: "end", mt: 3 }}>
        <Box>
          {setupScript?.isError &&
            setupScript?.error?.graphqlErrors?.map(
              (
                error: {
                  message:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                },
                index: any
              ) => {
                return (
                  <Alert severity="error" key={`${error.message}-${index}`}>
                    {error.message}
                  </Alert>
                );
              }
            )}

          {templateQuery?.isError && templateQuery?.error && (
            <Alert severity="error">{templateQuery?.error}</Alert>
          )}
        </Box>
        <Grid container sx={{ mt: 4 }}>
          <Grid item sx={{ width: "100%" }} xs={12}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <InputLabel>Region to Run Cloud Template</InputLabel>
              <Select
                inputProps={{
                  "data-testid": "region-select-input",
                }}
                data-testid="region-select"
                value={region}
                onChange={onChange}
                label="Region to Run Cloud Template"
              >
                {availableRegions?.map((_region: any | null | undefined) => (
                  <MenuItem value={_region as string} key={`${_region}`}>
                    {_region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <DisplayTemplate
        template={templateQuery.data}
        isLoading={templateQuery.isLoading}
        error={templateQuery.error}
      />
      <Box sx={{ textAlign: "end" }}>
        <Typography sx={{ mt: 2 }} variant="body2">
          If no window opens, please disable pop up blocker.
        </Typography>
      </Box>
    </Box>
  );
};
export default SelectTemplate;
